import React from 'react'

import OpenVideo from 'src/components/OpenVideo'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import textPT from 'src/pages/o-inter/assets/data/translate/institucional/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/institucional/textEN.json'
import { ContentType } from 'src/pages/o-inter/assets/data/translate/institucional/types'

import { SectionWrapper } from './style'
interface IInstitucional {
  lang: string;
}

const Institucional = ({ lang }: IInstitucional) => {
  const { title, titleSection, videoLink }: ContentType = lang === 'pt' ? textPT : textEN
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12'>
            <h1
              className='font-citrina text-white text-center fs-32 lh-38 fs-lg-40 lh-lg-48 fs-xl-48 lh-xl-58 my-4'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {videoLink &&
              <OpenVideo
                link={videoLink.url}
              >
                <button
                  className='btn text-white fw-600 fs-12 fs-md-14 lh-20 text-none mx-auto'
                  title={videoLink.text}
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: titleSection,
                      element_action: 'click video',
                      element_name: videoLink.text,
                      redirect_url: videoLink.url,
                    })
                  }}
                >
                  {videoLink.text}
                </button>
              </OpenVideo>
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default Institucional
