import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
   min-height: 673px;

   a {
    text-decoration: underline;
   }

  @media ${device.tablet} {
    min-height: 418px;
  }
  @media ${device.desktopLG} {
    min-height: 505px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }
`
