import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`

  @media ${device.tablet} {
    min-height: 434px;
  }
  @media ${device.desktopLG} {
    min-height: 500px;
  }
  @media ${device.desktopXL} {
    min-height: 642px;
  }
  @media ${device.desktopXXXL} {
    min-height: 856px;

    h2 {
      @media ${device.desktopXXXL} {
        font-size: 62px !important;
        line-height: 75px !important;
      }
    }
  }
`
