import styled from 'styled-components'
import { primary } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  background-color: ${primary[500]};
  align-items: center;
  display: flex;
  justify-content: center;  
  padding: 65px 0;
  min-height: 395px;

  @media ${device.tablet} {
    min-height: 236px;
  }
  @media ${device.desktopLG} {
    min-height: 245px;
    padding-top: 68px;
    padding-bottom: 68px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  
  h2 {
    @media ${device.desktopXXXL} {
      font-size: 53px !important;
      line-height: 64px !important;
    }
  }
`
