import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail, useSpring } from 'react-spring'
import ImageWebp from 'src/components/ImageWebp'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import textPT from 'src/pages/o-inter/assets/data/translate/tudo-no-mesmo-app/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/tudo-no-mesmo-app/textEN.json'
import { ContentType } from 'src/pages/o-inter/assets/data/translate/tudo-no-mesmo-app/types'

import { SectionWrapper } from './style'
interface ITudoNoMesmoApp {
  lang: string;
}

type ListType = {
  icon: string;
  text: string;
}

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const TudoNoMesmoApp = ({ lang }: ITudoNoMesmoApp) => {
  const { title, description, list }: ContentType = lang === 'pt' ? textPT : textEN

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <SectionWrapper ref={sectionRef} className='pt-5 pb-4 pt-md-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <animated.div style={animatedImage} className='col-12 col-md-6 col-lg-5 d-flex justify-content-center'>
            <ImageWebp
              arrayNumbers={[ 306.63, 338, 379.02, 458.44, 608.11 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/o-inter-dobra4/image.webp'
              altDescription='Homem sorrindo e olhando para celular com prédio ao fundo'
            />
          </animated.div>
          <div className='col-12 col-md-6 col-lg-5'>
            <animated.h2
              style={animatedSection[1]}
              className='font-citrina fs-24 lh-28 fs-md-25 lh-md-30 fs-lg-33 lh-lg-40 fs-xl-47 lh-xl-56 my-4'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <animated.p
              style={animatedSection[0]} className='text-grayscale--500 fs-18 lh-22'
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <animated.div style={animatedSection[0]} className='icon-section'>
              {list.map((item: ListType) => (
                <div key={item.icon} className='icon-div my-4'>
                  <OrangeIcon size='MD' color='#ff7A00' icon={item.icon} />
                  <p className='text-grayscale--500 fs-16 lh-20 fw-600 mx-3 my-0' dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              ))}
            </animated.div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default TudoNoMesmoApp
