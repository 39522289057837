
import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import LeftRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'

import textPT from 'src/pages/o-inter/assets/data/translate/premios/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/premios/textEN.json'

import { rewardsAndRecognitionsPT } from './assets/data/_rewardsAndRecognitionsPT'
import { rewardsAndRecognitionsEN } from './assets/data/_rewardsAndRecognitionsEN'

import { RewardsAndRecognitionsType } from './assets/data/types'
import { ContentType } from 'src/pages/o-inter/assets/data/translate/premios/types'
import { Section, Title, Subtitle, Card, CardTitle, CardDesc } from './style'

interface IPremios {
  lang: string;
}

export const Premios = ({ lang }: IPremios) => {
  const { title, titleSection, description, hasLink, linkText }: ContentType = lang === 'pt' ? textPT : textEN
  const rewardsAndRecognitions: RewardsAndRecognitionsType[] = lang === 'pt' ? rewardsAndRecognitionsPT : rewardsAndRecognitionsEN
  const [ sendDatalayerEvent ] = useDataLayer()

  const rightArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: never) => (
    <button
      onClick={onClick} data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <LeftRight width={40} height={40} color='orange--base' />
    </button>
  )

  return (
    <Section className='py-lg-5'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-4 col-lg-5 text-center text-md-left pr-lg-0'>
            <Title dangerouslySetInnerHTML={{ __html: title }} className='font-citrina' />
            {hasLink ? (
              <div
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: titleSection,
                    element_action: 'click button',
                    element_name: linkText,
                    redirect_url: hasLink,
                  })
                }}
              >
                <Subtitle dangerouslySetInnerHTML={{ __html: description }} className='mb-md-0' />
              </div>
            ) : (
              <Subtitle dangerouslySetInnerHTML={{ __html: description }} className='mb-md-0' />
            )}
          </div>
          <div className='col-xl-1 d-none d-xl-block' />
          <div className='col-12 col-md-8 col-lg-6 col-xl-6 pl-lg-0'>
            <DefaultCarousel
              sm={{
                items: 1,
              }}
              md={{
                items: 1,
              }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              customLeftArrow={leftArrow}
              customRightArrow={rightArrow}
            >
              {rewardsAndRecognitions.map((item: RewardsAndRecognitionsType) => (
                <Card key={item.title}>
                  <ImageWebp
                    arrayNumbers={[ 299, 200.67, 187.73, 264, 351.82 ]}
                    pathSrc={item.image.mobile}
                    altDescription={item.image.altText}
                  />
                  <div className='text-center text-md-left'>
                    <CardTitle dangerouslySetInnerHTML={{ __html: item.title }} className='font-citrina' />
                    {item.hasLink ? (
                      <span
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_05',
                            section_name: titleSection,
                            element_action: 'click button',
                            element_name: linkText,
                            redirect_url: hasLink,
                          })
                        }}
                      >
                        <CardDesc dangerouslySetInnerHTML={{ __html: item.description }} className='p5-md-3' />
                      </span>
                    ) : (
                      <CardDesc dangerouslySetInnerHTML={{ __html: item.description }} className='p5-md-3' />
                    )}
                  </div>
                </Card>
              ))}
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </Section>
  )
}
