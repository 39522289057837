import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Institucional from './sections/institucional/_Institucional'
import NascemosPara from './sections/nascemos-para/_NascemosPara'
import SimplificarVida from './sections/simplificar-a-vida/_index'
import TudoNoMesmoApp from './sections/tudo-no-mesmo-app/_index'
import { Premios } from './sections/premios/_index'
import NumerosEvolucao from './sections/numeros-evolucao/_NumerosEvolucao'
import NossaTrajetoria from './sections/nossa-trajetoria/_NossaTrajetoria'
import NasdaqListed from './sections/nasdaq-listed/_index'
import SeguimosEvoluindo from './sections/seguimos-evoluindo/_SeguimosEvoluindo'
import ImprensaEParcerias from './sections/imprensa-e-parcerias/_ImprensaEParcerias'
import FiquePorDentro from './sections/fique-por-dentro/_FiquePorDentro'

import pageContext from './pageContext.json'

const TheBankPT = () => {
  const lang = 'pt'

  return (
    <Layout pageContext={pageContext}>
      <Institucional lang={lang} />
      <NascemosPara lang={lang} />
      <SimplificarVida lang={lang} />
      <TudoNoMesmoApp lang={lang} />
      <Premios lang={lang} />
      <NumerosEvolucao lang={lang} />
      <NossaTrajetoria lang={lang} />
      <NasdaqListed lang={lang} />
      <SeguimosEvoluindo lang={lang} />
      <ImprensaEParcerias lang={lang} />
      <FiquePorDentro lang={lang} />
    </Layout>
  )
}

export default TheBankPT
