import React, { useRef, useEffect } from 'react'
import { animated, useTrail, useSpring } from 'react-spring'
import ImageWebp from 'src/components/ImageWebp'

import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'

import textPT from 'src/pages/o-inter/assets/data/translate/simplificar-a-vida/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/simplificar-a-vida/textEN.json'
import { ContentType } from 'src/pages/o-inter/assets/data/translate/simplificar-a-vida/types'
import { SectionWrapper } from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

interface ISimplificarVida {
  lang: string;
}

const SimplificarVida = ({ lang }: ISimplificarVida) => {
  const { title, description }: ContentType = lang === 'pt' ? textPT : textEN

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <SectionWrapper ref={sectionRef} className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 text-center text-md-left'>
            <animated.h2
              style={animatedSection[0]}
              className='font-citrina text-primary--500 fs-32 lh-38 fs-md-24 lh-md-28 fs-lg-33 lh-lg-40 fs-xl-47 lh-xl-56 mb-4'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <animated.p
              style={animatedSection[1]}
              className='text-grayscale--500 fs-18 lh-22 mb-0'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          <animated.div style={animatedImage} className='col-12 col-md-6 col-lg-5 mt-4 mt-md-0 d-flex justify-content-center'>
            <ImageWebp
              arrayNumbers={[ 318.7, 333.78, 365.98, 452.41, 603.31 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/o-inter-clientes/image.webp'
              altDescription='Mulheres sorrindo olhando aplicativo do Inter no celular'
            />
          </animated.div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default SimplificarVida
