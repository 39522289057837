import React, { useState, useEffect } from 'react'
import { Years } from './style'
import { grayscale, orange } from 'src/styles/colors'
import useWindowSize from '../../../../hooks/window/WindowSize'
import { CarouselMark } from '../../assets/data/translate/nossa-trajetoria/types'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG, WIDTH_XL, WIDTH_XXL } from 'src/styles/breakpoints'
interface ITimelinesYears {
  carouselIndexData: React.SetStateAction<number>;
  marks: CarouselMark[];
}

const TimelineYears = ({ carouselIndexData, marks }: ITimelinesYears) => {
  const data = marks
  const windowWidth = useWindowSize().sizes.width
  const width = useWidth()
  const [ carouselIndex, setCaroulselIndex ] = useState(0)
  const [ isLeft, setIsLeft ] = useState(90)

  useEffect(() => {
    setCaroulselIndex(carouselIndexData)
    if (width >= WIDTH_LG && width < WIDTH_XXL) {
      setIsLeft(310)
    } else if (width >= WIDTH_XL && width <= WIDTH_XXL) {
      setIsLeft(200)
    } else if (width > WIDTH_XXL) {
      setIsLeft(230)
    } else {
      setIsLeft(isLeft)
    }
  })

  return (
    <Years>
      <div className='d-flex justify-content-center'>
        {data.map((item: CarouselMark, index: number) =>
        (
          <h2
            className='fs-38 lh-46 font-citrina'
            dangerouslySetInnerHTML={{ __html: item.year }}
            key={index}
            style={{
                color: `${carouselIndex === item.id ? orange.extra : grayscale[300]}`,
                transform: `${carouselIndex === item.id ? 'scale(1.2)' : 'scale(0.9)'}`,
                width: '190px',
                position: 'absolute',
                zIndex: 2,
                transition: 'left 0.5s, transform 0.3s ease-in-out',
                left: `${((windowWidth * (width < WIDTH_LG ? 0.5 : 0.57)) - (isLeft)) + (index - carouselIndex) * 265}px`,
            }}
          />
        ))}
      </div>
    </Years>
  )
}

export default TimelineYears
