import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

export const SectionWrapper = styled.section`
  background-color: ${primary[500]};

  @media(min-width: ${breakpoints.md}) {
    min-height: 636px;
  }

  h2 {
    font-size: 32px;
    line-height: 38.4px;

    @media(min-width: ${breakpoints.md}) {
      font-size: 24px;
      line-height: 28.8px;  
    }
    @media(min-width: ${breakpoints.lg}) {
      font-size: 28.45px;
      line-height: 34.14px;  
    }
    @media(min-width: ${breakpoints.xl}) {
      font-size: 40px;
      line-height: 48px;  
    }
    @media(min-width: ${breakpoints.xxxl}) {
      font-size: 53.22px;
      line-height: 63.87px;  
    }
  }

  .social-media-cards {
    margin-top: 30px;
    
    .social-media-card {
      padding: 20px;
      min-height: 160px;
      background: #FCF8EE;

      p {
        font-size: 23.39px;
        line-height: 28.3px;

        @media(min-width: ${breakpoints.lg}) {
          font-size: 13.25px;
          line-height: 16.03px;  
        }
        @media(min-width: ${breakpoints.xl}) {
          font-size: 16px;
          line-height: 17px;  
        }
        @media(min-width: ${breakpoints.xxxl}) {
          font-size: 21.29px;
          line-height: 25.76px;  
        }
        span {
          margin-left: 15px;
        }
      }
    }
  }
  a {
    font-size: 18px;
    line-height: 22px;  

    @media(min-width: ${breakpoints.md}) {
      font-size: 20px;
      line-height: 24px;  
    }
    @media(min-width: ${breakpoints.lg}) {
      font-size: 11.59px;
      line-height: 14.03px;  
    }
    @media(min-width: ${breakpoints.xl}) {
      font-size: 14px;
      line-height: 17px;  
    }

    span {
      margin-right: 10px;
      
      @media(min-width: ${breakpoints.md}) {
        margin-right: 35px;
      }
    }
  }

  .social-media-card {
    margin: 0 0 25px 0;
  }
`
