import React from 'react'
import marketingLinksJSON from './material-divulgacao.json'
import PressCard from './_PressCard'

import textPT from 'src/pages/o-inter/assets/data/translate/imprensa-e-parcerias/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/imprensa-e-parcerias/textEN.json'
import { ContentType } from 'src/pages/o-inter/assets/data/translate/imprensa-e-parcerias/types'

import { SectionWrapper } from './style'
interface IImprensaEParceria {
  lang: string;
}

type cardContentProps = {
  title: string;
  subtitle: string;
  contentLink: string;
  emails: string[];
}

const ImprensaEParceria = ({ lang }: IImprensaEParceria) => {
  const { title, titleSection, description, pressAndPartnerships }: ContentType = lang === 'pt' ? textPT : textEN

  const cardContent = pressAndPartnerships
  const marketingLinks = marketingLinksJSON

  return (
    <SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <h2
              className='font-citrina text-grayscale--500 fs-32 lh-38 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-48 mb-3'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className='fs-14 lh-17 text-grayscale--500 font-inter mb-5'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <div className='row justify-content-between align-items-center blocks'>
          {cardContent.map((item: cardContentProps, index: number) => (
            <div key={index} className='col-12 col-lg-6 press-card'>
              <PressCard
                sectionTitle={titleSection}
                title={item.title}
                subtitle={item.subtitle}
                emails={item.emails}
                contentLink={item.contentLink}
                link={
                  item.title === 'Fotos e logo' || item.title === 'Photos and logo' ? marketingLinks.presskit
                  : item.title === 'Relações com Investidores' || item.title === "#TôNoInter - visit Inter's office" ? marketingLinks['to-no-inter']
                  : ''
                }
              />
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  )
}

export default ImprensaEParceria
