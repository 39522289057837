import React from 'react'
import { SectionWrapper } from './style'

import textPT from 'src/pages/o-inter/assets/data/translate/numeros-evolucao/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/numeros-evolucao/textEN.json'

import { ContentType } from 'src/pages/o-inter/assets/data/translate/numeros-evolucao/types'

interface INumerosEvolucao {
  lang: string;
}

type CardType = {
  evolution: string;
  description: string;
}

const NumerosEvolucao = ({ lang }: INumerosEvolucao) => {
  const { title, evolutionCards }: ContentType = lang === 'pt' ? textPT : textEN

  return (
    <SectionWrapper className='py-5'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 text-center'>
            <h2
              className='font-citrina text-white fs-32 lh-40 fs-xl-40 lh-xl-48 mb-4'
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <div className='row justify-content-center align-items-center mt-4'>
            {evolutionCards.map((item: CardType) => (
              <div key={item.evolution} className='px-4 border rounded-4 card-div mb-4 mb-md-0'>
                <h3 className='font-citrina text-orange--extra' dangerouslySetInnerHTML={{ __html: item.evolution }} />
                <p className='text-white mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default NumerosEvolucao
