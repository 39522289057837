/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

const SocialMediaCard = (props: any) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const handleClick = (url: string, element: string) => {
    sendDatalayerEvent({
      section: 'dobra_11',
      section_name: props.title,
      element_action: 'click button',
      element_name: element,
      redirect_url: url,
    })

    window.open(url, '_blank')
  }
  return (
    <div className='border rounded-4 social-media-card row'>
      <div className='col-12'>
        <p className='text-orange--extra fw-600'>
          <Icon color='orange--base' width={width <= WIDTH_MD ? 32 : 25} icon={props.icon} directory='v2' />
          <span dangerouslySetInnerHTML={{ __html: props.socialMedia }} />
        </p>
      </div>
      <div className='col-12'>
        <p
          className='text-grayscale--500 fw-600 limited-p'
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
        <a
          onClick={() => handleClick(props.link, props.descriptionLink)}
          className='text-orange--extra cursor-pointer'
        >
          <span dangerouslySetInnerHTML={{ __html: props.descriptionLink }} />
          <Icon color='orange--base' width={width <= WIDTH_MD ? 32 : 25} icon='navigation/arrow-right' directory='v2' />
        </a>
      </div>
    </div>
  )
}

export default SocialMediaCard
