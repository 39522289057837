import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'

export const SectionWrapper = styled.section`
  background: #F7CA96;

  h2 {
    color: ${orange.dark};

    @media ${device.desktopXXXL} {
      font-size: 62px !important;
      line-height: 75px !important;
    }
  }

  @media ${device.tablet} {
    min-height: 551px;
  }
  @media ${device.desktopLG} {
    min-height: 607px;
  }
  @media ${device.desktopXL} {
    min-height: 730px;
  }

  .icon-section {
    .icon-div {
      display: flex;
      align-items: center;
    }
    p {
      font-family: 'Sora';
    }
  }
`
