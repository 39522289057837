import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail, useSpring } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

import textPT from 'src/pages/o-inter/assets/data/translate/nasdaq-listed/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/nasdaq-listed/textEN.json'

import { ContentType, IDescription } from 'src/pages/o-inter/assets/data/translate/nasdaq-listed/types'
import { SectionWrapper } from './style'

interface ISeguimosEvoluindo {
  lang: string;
}

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const NasdaqListed = ({ lang }: ISeguimosEvoluindo) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { title, description }: ContentType = lang === 'pt' ? textPT : textEN

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <SectionWrapper ref={sectionRef} className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row justify-content-center justify-content-md-between align-items-center'>
          <animated.div style={animatedImage} className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 text-center pt-1'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/o-inter-nasdaq/image.webp'
              altDescription='Fachada do prédio sede da Nasdaq em New York com logo Inter&Co'
              arrayNumbers={[ 305, 331, 369, 456, 608.42 ]}
            />
          </animated.div>
          <div className='col-10 col-md-6 text-center text-md-left'>
            <animated.h2
              style={animatedSection[0]}
              className='font-citrina text-grayscale--500 fs-32 lh-40 fs-lg-36 lh-lg-43 fs-xl-48 lh-xl-56 my-4'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {description.map((item: IDescription, index: number) => (
              <div key={index}>
                {item.hasLink ? (
                  <div
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_08',
                        section_name: title,
                        element_action: 'click button',
                        element_name: item.linkText ? item.linkText : 'null',
                        redirect_url: item.hasLink,
                      })
                    }}
                  >
                    <animated.p
                      key={item.p}
                      style={animatedSection[index + 1]} className='text-grayscale--500 fs-18 lh-22'
                      dangerouslySetInnerHTML={{ __html: item.p }}
                    />
                  </div>
                ) : (
                  <animated.p
                    key={item.p}
                    style={animatedSection[index + 1]} className='text-grayscale--500 fs-18 lh-22'
                    dangerouslySetInnerHTML={{ __html: item.p }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default NasdaqListed
