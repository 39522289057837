import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  min-height: 860px;
  background-color: #FCF8EE;

  @media(min-width: ${breakpoints.md}) {
    min-height: 605px;
  }
  @media(min-width: ${breakpoints.lg}) {
    height: 510px;
  }
  @media(min-width: ${breakpoints.xl}) {
    height: 718px;
  }

  .line {
    border-top: 1px solid ${grayscale[200]};
    width: 100%;
    height: 1px;
    margin-top: 25px;
    position: absolute;
    z-index: 1;
  }

  ul.react-multi-carousel-track {
    height: 470px;

    @media(min-width: ${breakpoints.md}) {
      height: 270px;
    }
    @media(min-width: ${breakpoints.xl}) {
      height: 320px;
    }
  }
  .react-multiple-carousel {
    &__arrow--right {
      @media(min-width: ${breakpoints.lg}) {
        right: 30%;
      }
    }
    &__arrow--left {
      @media(min-width: ${breakpoints.lg}) {
        left: 30%;
      }
    }
  }
  .react-multi-carousel-dot {
    button {
      background-color: ${orange.extra};
    }
  }
`

export const Years = styled.div`
  width: 100%;
  height: 70px;
  position: relative;
  overflow: hidden;
`

export const Desc = styled.p`
  max-width: 750px;
  margin: 0 auto;
`
