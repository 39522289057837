import React from 'react'
import SocialMediaCard from './_SocialMediaCard'

import textPT from 'src/pages/o-inter/assets/data/translate/fique-por-dentro/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/fique-por-dentro/textEN.json'

import { ContentType, SocialMediaCardType } from 'src/pages/o-inter/assets/data/translate/fique-por-dentro/types'
import { SectionWrapper } from './style'
interface IFiquePorDentro {
  lang: string;
}

const FiquePorDentro = ({ lang }: IFiquePorDentro) => {
  const { title, description, socialMedia }: ContentType = lang === 'pt' ? textPT : textEN
  const cardsInformation = socialMedia

  return (
    <SectionWrapper className='pt-5 pb-4 py-md-5'>
      <div className='container pt-md-3 pb-md-2 pt-lg-5 pb-lg-4'>
        <div className='row'>
          <div className='col-9 col-md-12 mx-auto text-center'>
            <h2
              className='font-citrina text-white mb-3 px-5'
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className='font-sora text-white font-inter fs-18 lh-22'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <div className='row justify-content-center align-items-center social-media-cards'>
          {cardsInformation.map((item: SocialMediaCardType, index: number) => (
            <div key={index} className='col-12 col-lg-6'>
              <SocialMediaCard
                socialMedia={item.socialMedia}
                description={item.description}
                descriptionLink={item.descriptionLink}
                link={item.link}
                icon={item.icon}
                title={title}
              />
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FiquePorDentro
