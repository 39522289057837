import React, { useState } from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import TimelineYears from './_TimelineYears'

import textEN from 'src/pages/o-inter/assets/data/translate/nossa-trajetoria/textEN.json'
import textPT from 'src/pages/o-inter/assets/data/translate/nossa-trajetoria/textPT.json'

import { ContentType } from 'src/pages/o-inter/assets/data/translate/nossa-trajetoria/types'
import { Desc, SectionWrapper } from './style'
interface INossaTrajetoria {
  lang: string;
}

type carouselDataProps = {
  id: number;
  year: string;
  description: string;
}

const NossaTrajetoria = ({ lang }: INossaTrajetoria) => {
  const { title, description, marks }: ContentType = lang === 'pt' ? textPT : textEN
  const carouselData = marks

  const [ carouselIndex, setCaroulselIndex ] = useState(0)

  function changeCaroulselSelected (nextSlide: number) {
    setCaroulselIndex(nextSlide)
  }

  return (
    <SectionWrapper className='pt-1'>
      <div className='row mx-0 py-5'>
        <div className='col-12 col-md-11 col-lg-10 col-xl-6 mx-auto align-items-center text-center px-4'>
          <h2
            className='font-citrina text-grayscale--500 fs-24 lh-28 fs-md-26 lh-md-30 fs-lg-34 lh-lg-40 fs-xl-48 lh-xl-60 mb-4'
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p className='text-grayscale--500 fs-18 lh-22' dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
      <div className='line' />
      <TimelineYears carouselIndexData={carouselIndex} marks={marks} />
      <div className='col-12 mx-auto px-0 pb-5'>
        <DefaultCarousel
          sm={{
            items: 1,
          }}
          md={{
            items: 1,
          }}
          lg={{
            items: 1,
          }}
          xl={{
            items: 1,
          }}
          beforeChange={(nextSlide: number) => changeCaroulselSelected(nextSlide)}
        >
          {
            carouselData.map((item: carouselDataProps, index: number) => (
              <div className='container' key={index}>
                <div className='row'>
                  <div className='col-9 col-md-8 px-0 mx-auto text-center' key={index}>
                    <Desc
                      className='text-grayscale--500 fs-18 lh-22'
                      dangerouslySetInnerHTML={{ __html: `${carouselIndex === item.id ? item.description : ''}` }}
                    />
                  </div>
                </div>
              </div>
            ))
          }
        </DefaultCarousel>
      </div>
    </SectionWrapper>
  )
}

export default NossaTrajetoria
