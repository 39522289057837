
import { useState, useEffect } from 'react'

type CurrentSize = {
  'breakpoint': string;
  'sizes': {
    width: number;
    height: number;
  };
}

const useWindowSize = () => {
  const [ size, setWindowSize ] = useState([ 0, 0 ])

  useEffect(() => {
    function handleResize () {
      setWindowSize([ window.innerWidth, window.innerHeight ])
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return findCurrentWindowSize(size)
}

function findCurrentWindowSize (size: number[]) {
  const currentSize: CurrentSize = {
    breakpoint: '',
    sizes: {
      width: 0,
      height: 0,
    },
  }

  const width = size[0]
  currentSize.sizes = {
    width: size[0],
    height: size[1],
  }

  if (width >= 0 && width <= 576) {
    currentSize.breakpoint = 'sm'
  } else if (width > 576 && width <= 768) {
    currentSize.breakpoint = 'md'
  } else if (width > 768 && width <= 992) {
    currentSize.breakpoint = 'lg'
  } else if (width > 992 && width <= 1200) {
    currentSize.breakpoint = 'xl'
  } else if (width > 1200) {
    currentSize.breakpoint = 'xxl'
  }

  return currentSize
}

export default useWindowSize
