import styled from 'styled-components'
import { orange } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  align-items: center;
  display: flex;
  min-height: 410px;
  background: ${orange.dark};

  @media ${device.desktopLG} {
    min-height: 546px;
  }
  @media ${device.desktopXL} {
    min-height: 100vh;
  }
  
  h1 {
    @media ${device.desktopXXXL} {
      font-size: 64px !important;
      line-height: 76px !important;
    }
  }

  button {
    background: ${orange.extra};
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    border-radius: 8px;
    height: 48px;

    @media ${device.tablet} {
      max-width: 328px;
    }
    @media ${device.desktopLG} {
      max-width: 230px;
    }
    @media ${device.desktopXL} {
      max-width: 323px;
    }
    @media ${device.desktopXXXL} {
      max-width: 430px;
    }
  }
`
