import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail } from 'react-spring'

import textPT from 'src/pages/o-inter/assets/data/translate/nascemos-para/textPT.json'
import textEN from 'src/pages/o-inter/assets/data/translate/nascemos-para/textEN.json'

import { ContentType } from 'src/pages/o-inter/assets/data/translate/nascemos-para/types'
import { SectionWrapper } from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

interface INascemosPara {
  lang: string;
}

const NascemosPara = ({ lang }: INascemosPara) => {
  const { title, description }: ContentType = lang === 'pt' ? textPT : textEN

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(2, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

  return (
    <SectionWrapper ref={sectionRef}>
      <div className='container'>
        <div className='row align-items-center justify-content-between justify-content-xl-center text-center text-md-left'>
          <div className='col-12 col-md-3 col-lg-4 col-xl-3'>
            <animated.h2
              style={animatedSection[0]}
              className='font-citrina fs-32 lh-38 fs-md-24 lh-md-28 fs-lg-30 lh-lg-36 fs-xl-40 lh-xl-48 text-white mb-4 mb-md-0'
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>
          <div className='col-12 col-md-6'>
            <animated.p
              style={animatedSection[1]}
              className='fs-18 lh-22 text-grayscale--100 mb-0'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>

    </SectionWrapper>
  )
}

export default NascemosPara
