import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
const numerosEvolucaoMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-inter-mobile/image.webp'
const numerosEvolucaoTabletDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/background-inter-web1/image.webp'

export const SectionWrapper = styled.section`
  align-items: center;
  display: flex;
  background: url(${numerosEvolucaoMobile}) no-repeat;
  background-size: cover;
  min-height: 929px;

  @media(min-width: ${breakpoints.md}) {
    background: url(${numerosEvolucaoTabletDesktop}) no-repeat;
    background-size: cover;
    min-height: 386px;
  }  
  
  @media(min-width: ${breakpoints.lg}) {
    background: url(${numerosEvolucaoTabletDesktop}) no-repeat;
    background-size: cover;
    min-height: 485px;
  }   

  @media(min-width: ${breakpoints.xl}){
    background: url(${numerosEvolucaoTabletDesktop}) no-repeat;
    background-size: cover;
    min-height: 681px;
  }  

  @media(min-width: ${breakpoints.xxxl}){
    background: url(${numerosEvolucaoTabletDesktop}) no-repeat;
    background-size: cover;
    min-height: 908px;
  }  

  .card-div {
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 270px;
    min-height: 240px;
    text-align: center;
    width: 100%;

    h3 {
      font-size: 42px;
      line-height: 50px;
      margin-bottom: 16px;

      @media(min-width: ${breakpoints.md}) {
        font-size: 29px;
        line-height: 35px;
      }
      @media(min-width: ${breakpoints.lg}) {
        font-size: 32px;
        line-height: 40px;
      }
      @media(min-width: ${breakpoints.xxxl}) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    p {
      font-size: 22px;
      line-height: 27px;

      @media(min-width: ${breakpoints.md}) {
        font-size: 16px;
        line-height: 20px;
      }
      @media(min-width: ${breakpoints.xxxl}) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    @media(min-width: ${breakpoints.md}) {
      min-height: 234px;
      max-width: 215px;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }  
    @media(min-width: ${breakpoints.lg}) {
      margin-right: 68px;     
    }  
    @media(min-width: ${breakpoints.xl}) {
      max-width: 263px;
      margin-right: 74px;     
    }  
  }
`
