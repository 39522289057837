/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'
import { Link } from 'gatsby'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const PressCard = (props: any) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClick = (url: string, element: string) => {
    sendDatalayerEvent({
      section: 'dobra_10',
      section_name: props.sectionTitle,
      element_action: 'click button',
      element_name: element,
      redirect_url: url,
    })

    window.open(url, '_blank')
  }

  return (
    <div className='border rounded-4 block'>
      <p
        className='font-citrina text-orange--extra fs-20 lh-24 fw-600'
        dangerouslySetInnerHTML={{ __html: props.title }}
      />
      <div className='flex-wrap'>
        <p
          className='text-grayscale--500 fs-16 lh-20 fw-600 mb-3 subtitle'
          dangerouslySetInnerHTML={{ __html: props.subtitle }}
        />
        {props.contentLink ? (
          <Link
            to={props.link}
            className='fs-14 text-orange--extra'
            title='Baixar arquivos'
            target='_blank'
            rel='noreferrer'
          >
            <div onClick={() => handleClick(props.link, props.contentLink)}>
              <span dangerouslySetInnerHTML={{ __html: props.contentLink }} />
              <Icon
                color='orange--base'
                width='25'
                icon='navigation/arrow-right'
                directory='v2'
              />
            </div>
          </Link>
        ) : (
          ''
        )}
      </div>

      {props.emails
        ? props.emails.map((item: string, index: number) => (
          <a
            key={index}
            onClick={() => handleClick(`mailto:${item}`, props.contentLink)}
            className='d-block'
          >
            <p
              className='text-orange--extra fs-16 lh-20 fw-600'
              dangerouslySetInnerHTML={{ __html: item }}
            />
          </a>
          ))
        : ''}
    </div>
  )
}

export default PressCard
