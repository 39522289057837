import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  a {
    text-decoration: underline;
   }
  @media ${device.tablet} {
    min-height: 421px;
  }
  @media ${device.desktopLG} {
    min-height: 504px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }
`
