import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`

.react-multiple-carousel__arrow {
    svg {
      fill: ${orange.extra};
    }
  }

  .react-multi-carousel-dot {
    button {
      background-color: ${orange.extra};
    }
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #72370E;
  margin-bottom: 16px;

  @media ${device.desktopLG} {
    font-size: 32px;
    line-height: 40px;
  }
  @media ${device.desktopXL} {
    font-size: 40px;
    line-height: 48px;
  }
  @media ${device.desktopXXXL} {
    font-size: 53.31px;
    line-height: 63.97px;
  }
`

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  a {
    font-weight: 700;
  }

  @media ${device.desktopLG} {
    font-size: 14px;
    line-height: 16px;
  }
  @media ${device.desktopXL} {
    font-size: 18px;
    line-height: 22px;
  }
  @media ${device.desktopXXXL} {
    font-size: 24px;
    line-height: 30px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    margin: 0 auto;
  }

  @media ${device.tablet} {
    flex-direction:row;
    align-items: center;

    img {
      margin-right: 24px;
    }
  }
  @media ${device.desktopLG} {
    margin-left: 10px;
  }
`

export const CardTitle = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${grayscale[500]};
  margin-top: 24px;
  margin-bottom: 8px;

  @media ${device.tablet} {
    margin-top: 0;
  }
  @media ${device.desktopLG} {
  }
  @media ${device.desktopXL} {
    font-size: 24px;
    line-height: 28px;
  }
  @media ${device.desktopXXXL} {
    font-size: 32px;
    line-height: 40px;
  }
`

export const CardDesc = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12.16px;
  line-height: 14.72px;
  color: ${grayscale[500]};
  margin-bottom: 0;

  @media ${device.desktopLG} {
    font-size: 12px;
    line-height: 14px;
  }
  @media ${device.desktopXL} {
    font-size: 16px;
    line-height: 20px;
  }
  @media ${device.desktopXXXL} {
    font-size: 21.32px;
    line-height: 25.8px;
  }
`
