const imageOne = 'https://central-imagens.bancointer.com.br/images-without-small-versions/o-inter-card1a/image.webp'
const imageTwo = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-card1/image.webp'
const imageThree = 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-card2/image.webp'
const imageFour = 'https://central-imagens.bancointer.com.br/images-without-small-versions/o-inter-card3/image.webp'

export const rewardsAndRecognitionsPT = [
  {
    title: 'Selo Empresa Pró-Ética',
    description: 'O Inter recebeu o selo Empresa Pró-Ética 2022-2023, da Controladoria Geral da União (CGU), em reconhecimento à sua integridade e ética. O selo reafirma a importância da transparência no meio corporativo tanto do setor público quanto do privado.',
    hasLink: '',
    linkText: '',
    image: {
      mobile: imageOne,
      altText: 'Selo empresa pró-ética 2022 e 2023',
    },
  },
  {
    title: '100 pessoas inovadoras da América Latina',
    description: 'João Vitor Menin, CEO do Inter, foi reconhecido pela Bloomberg Línea por sua contribuição para o avanço da companhia e o desenvolvimento do Brasil, através de soluções inovadoras em sua área de atuação.',
    hasLink: '',
    linkText: '',
    image: {
      mobile: imageTwo,
      altText: 'João Vitor Menin CEO Inter sorrindov',
    },
  },
  {
    title: 'World\'s Top Fintech Companies',
    description: 'O Inter foi reconhecido pelo portal internacional de notícias CNBC e pelo portal de estatísticas e estudos de mercado Statista  como uma das 200 melhores fintechs do mundo em 2023.',
    hasLink: '',
    linkText: '',
    altText: '',
    image: {
      mobile: imageThree,
      altText: 'Fachada do prédio do Inter com logo',
    },
  },
  {
    title: '<span class=\'d-md-block\'>IFLR Americas</span> Awards',
    description: 'Em 2023, o Inter venceu a categoria Deal of the year: Equity pelo sucesso na operação de reorganização societária e listagem das ações da holding Inter&Co na Nasdaq.',
    hasLink: '',
    linkText: '',
    image: {
      mobile: imageFour,
      altText: 'Logo do prêmio IFLR Americas 2023',
    },
  },
]
