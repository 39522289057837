import styled from 'styled-components'

export const SectionWrapper = styled.section`
  background: #FCF8EE;
  
  .blocks {
    
    .block {
      word-break: break-word;
      padding: 20px;
      min-height: 190px;
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    span {
      margin-right: 10px;
    }
  }
`
